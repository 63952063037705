// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acl-tears-js": () => import("./../../../src/pages/acl-tears.js" /* webpackChunkName: "component---src-pages-acl-tears-js" */),
  "component---src-pages-appointments-directions-js": () => import("./../../../src/pages/appointments-directions.js" /* webpackChunkName: "component---src-pages-appointments-directions-js" */),
  "component---src-pages-arthroscopy-js": () => import("./../../../src/pages/arthroscopy.js" /* webpackChunkName: "component---src-pages-arthroscopy-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-canine-shoulder-disorders-js": () => import("./../../../src/pages/canine-shoulder-disorders.js" /* webpackChunkName: "component---src-pages-canine-shoulder-disorders-js" */),
  "component---src-pages-disc-js": () => import("./../../../src/pages/disc.js" /* webpackChunkName: "component---src-pages-disc-js" */),
  "component---src-pages-doctors-bauer-dvm-js": () => import("./../../../src/pages/doctors/bauer-dvm.js" /* webpackChunkName: "component---src-pages-doctors-bauer-dvm-js" */),
  "component---src-pages-doctors-dr-andra-detora-js": () => import("./../../../src/pages/doctors/dr-andra-detora.js" /* webpackChunkName: "component---src-pages-doctors-dr-andra-detora-js" */),
  "component---src-pages-doctors-dr-brian-dent-js": () => import("./../../../src/pages/doctors/dr-brian-dent.js" /* webpackChunkName: "component---src-pages-doctors-dr-brian-dent-js" */),
  "component---src-pages-doctors-dr-leah-hixon-js": () => import("./../../../src/pages/doctors/dr-leah-hixon.js" /* webpackChunkName: "component---src-pages-doctors-dr-leah-hixon-js" */),
  "component---src-pages-doctors-dr-lindsay-peterson-js": () => import("./../../../src/pages/doctors/dr-lindsay-peterson.js" /* webpackChunkName: "component---src-pages-doctors-dr-lindsay-peterson-js" */),
  "component---src-pages-doctors-dr-michael-detora-js": () => import("./../../../src/pages/doctors/dr-michael-detora.js" /* webpackChunkName: "component---src-pages-doctors-dr-michael-detora-js" */),
  "component---src-pages-doctors-dr-nic-cabano-js": () => import("./../../../src/pages/doctors/dr-nic-cabano.js" /* webpackChunkName: "component---src-pages-doctors-dr-nic-cabano-js" */),
  "component---src-pages-doctors-dr-scot-swainson-js": () => import("./../../../src/pages/doctors/dr-scot-swainson.js" /* webpackChunkName: "component---src-pages-doctors-dr-scot-swainson-js" */),
  "component---src-pages-doctors-dr-todd-riecks-js": () => import("./../../../src/pages/doctors/dr-todd-riecks.js" /* webpackChunkName: "component---src-pages-doctors-dr-todd-riecks-js" */),
  "component---src-pages-elbow-dysplasia-js": () => import("./../../../src/pages/elbow-dysplasia.js" /* webpackChunkName: "component---src-pages-elbow-dysplasia-js" */),
  "component---src-pages-fractures-dislocations-js": () => import("./../../../src/pages/fractures-dislocations.js" /* webpackChunkName: "component---src-pages-fractures-dislocations-js" */),
  "component---src-pages-hip-dysplasia-js": () => import("./../../../src/pages/hip-dysplasia.js" /* webpackChunkName: "component---src-pages-hip-dysplasia-js" */),
  "component---src-pages-hospital-gallery-js": () => import("./../../../src/pages/hospital-gallery.js" /* webpackChunkName: "component---src-pages-hospital-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medial-patella-luxation-js": () => import("./../../../src/pages/medial-patella-luxation.js" /* webpackChunkName: "component---src-pages-medial-patella-luxation-js" */),
  "component---src-pages-payment-options-js": () => import("./../../../src/pages/payment-options.js" /* webpackChunkName: "component---src-pages-payment-options-js" */),
  "component---src-pages-preparing-surgery-js": () => import("./../../../src/pages/preparing-surgery.js" /* webpackChunkName: "component---src-pages-preparing-surgery-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-total-hip-replacement-js": () => import("./../../../src/pages/total-hip-replacement.js" /* webpackChunkName: "component---src-pages-total-hip-replacement-js" */),
  "component---src-pages-tplo-js": () => import("./../../../src/pages/tplo.js" /* webpackChunkName: "component---src-pages-tplo-js" */)
}

